import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useReadRideStats } from "../../../hooks/useRides";
import { useReadMap } from "../../../hooks/useMaps";

import { driverClientCard } from "../../../models/userModels";

import GoBackButton from "../../../components/GoBackButton";
import Header from "../../../components/Header";
import ReservationInfo from "../../../components/reservation/ReservationInfo";
import { useReadBookings } from "../../../hooks/useBookings";
import { useReadVehicles } from "../../../hooks/useVehicles";
import { useReadDrivers } from "../../../hooks/useDrivers";
import { useReadUsers } from "../../../hooks/useUsers";

function UserReservationDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { booking, getBookingById } = useReadBookings();
  const { vehicle, getVehicleById } = useReadVehicles();
  const { driver, getDriverById } = useReadDrivers();
  const { user, getUserById } = useReadUsers();
  const { initialCoordinates, endCoordinates, getRoute, getPlaceCoordinates } = useReadMap();

  const [moveToDrive, setMoveToDrive] = useState(true);
  const [coordinates, setCoordinates] = useState(null);
  const [driveStructure, setDriverStructure] = useState(null);

  useEffect(() => {
    if (booking) {
      if (booking.ride.status !== "Pending") {
        navigate(`start/${booking.ride._id}`);
      } else {
        getVehicleById(booking.ride.vehicle);
        getDriverById(booking.ride.driver);
        getUserById(booking.ride.assignedBy);
      }
    }
  }, [booking]);

  useEffect(() => {
    if (id) {
      getBookingById(id);
    }
  }, [id]);

  useEffect(() => {
    if (initialCoordinates && endCoordinates && booking?.status === "Pending") {
      getRoute(initialCoordinates, endCoordinates);
    }
  }, [initialCoordinates, endCoordinates, coordinates]);

  return (
    <section className="formLayout">
      <section className="formLayout__title">
        <Header name="Viajes Asignados" />
      </section>
      <section className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={() => {
              navigate(-1);
            }}
            title="Consulte los detalles del viaje"
            subtitle="Verifique los detalles del viaje como el conductor, el vehículo y más"
          />
        </div>
        <div className="formLayout__form">
          <ReservationInfo
            vehicleSelected={vehicle}
            driverSelected={driver}
            booking={booking}
            admin={user}
          />
        </div>
      </section>
      <section className="formLayout__buttons">
        <button
          className="action-button action-button--secondary tripRoute__button"
          onClick={() => navigate("start")}
        >
          Simular el inicio del viaje
        </button>
      </section>
    </section>
  );
}

export default UserReservationDetail;
