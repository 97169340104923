import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoUserImage from "../assets/icons/user.png";
import RouteLine from "../assets/svg/RouteLine";
import routes from "../models/routes";
import RouteMap from "./maps/routeMap";

const TripTouristRow = ({ trip, selected }) => {
  const navigate = useNavigate();

  const isMobileView = window.innerWidth <= 810;

  if (trip && (trip.status !== "Pending" && trip.status !== "Completed" && !isMobileView)) {
    navigate(`${trip.booking._id}/start/${trip._id}`);
  }

  const [routeCoordinates, setRouteCoordinates] = useState(null);


  const handleCard = (tripData) => {
    if (isMobileView) {
      navigate(tripData.booking._id);
    } else {
      selected(tripData);
    }
  };

  const splittedRoutes = trip.booking.ticket.route.split(" - ");

  useEffect(() => {
    if (trip.booking.ticket.route) {
      setRouteCoordinates(routes.find((route) => route.name === trip.booking.ticket.route));
    }
  }, [trip.booking.ticket.route]);

  return (
    <div className="tripCard">
      <div
        className="tripCard__desktop"
        onClick={() => handleCard(trip)}
      >
        <div className="dataTable__row">
          <img
            src={trip?.driver.user.profilePicture !== null ? trip.driver.user.profilePicture : NoUserImage}
            alt="User"
          />
          <div className="dataTable__rowItems">
            <p>{trip.booking.ticket.route}</p>
            <p>{trip.driver.user.name}</p>
            <p>{trip.driver.user.lastName}</p>
          </div>
        </div>
      </div>

      <div
        className="tripCard__mobile"
        onClick={() => handleCard(trip)}
      >
        <RouteMap routeCoordinates={routeCoordinates} />
        <div className="tripCard__info">
          <div className="tripCard__destiny">
            <RouteLine />
            <div className="tripCard__destinyRows">
              <div className="tripCard__location">
                <p className="tripCard__locationName">
                  <strong>
                    {splittedRoutes[0]} | {trip.booking.ticket.pickUpTime}
                  </strong>
                </p>
                <p className="tripCard__locationDescription">{trip.booking.ticket.pickUpLocation}</p>
              </div>
              <div className="tripCard__location">
                <p className="tripCard__locationName">
                  <strong>
                    {splittedRoutes[1]} | {trip.booking.ticket.dropOffTime}
                  </strong>
                </p>
                <p className="tripCard__locationDescription">{trip.booking.ticket.dropOffLocation}</p>
              </div>
            </div>
          </div>
          <div className="tripCard__client">
            <p>Driver's name</p>
            <div className="tripCard__clientInfo">
              <img
                src={trip?.driver.user.profilePicture !== null ? trip.driver.user.profilePicture : NoUserImage}
                alt="User"
              />
              <div className="tripCard__clientName">
                <h4>
                  {trip.driver.user.name} {trip.driver.user.lastName}
                </h4>
                <p className="tripCard__clientNumber">{trip.driver.user.phone}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripTouristRow;
